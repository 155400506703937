<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <!-- <el-breadcrumb-item>
          <router-link :to="`/private/${$route.params.privateType}`">
            {{
              `${
                isCounseling
                  ? $t("pageTitle.counseling")
                  : $t("pageTitle.privateLessons")
              }`
            }}
          </router-link>
        </el-breadcrumb-item> -->
        <el-breadcrumb-item v-if="isPrivateLesson">
          <router-link
            :to="
              `/private/${$route.params.privateType}/${$route.params.classId}/detail`
            "
          >
            <IsOnlineIcon :isOnline="sessionClass.course_session.is_online" />
            {{ sessionClass.title }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-else>
          <router-link
            :to="{ name: 'classDetail', params: { id: $route.params.classId } }"
          >
            <IsOnlineIcon :isOnline="sessionClass.course_session.is_online" />
            {{ sessionClass.title }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <span v-if="isEditMode">
            {{ $t("pageTitle.editProgressReport") }}
          </span>
          <span v-else>
            {{ $t("pageTitle.addProgressReport") }}
          </span>
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <el-divider />
    <div>
      <h1 v-if="isEditMode">{{ $t("pageTitle.editProgressReport") }}</h1>
      <h1 v-else>{{ $t("pageTitle.addProgressReport") }}</h1>
    </div>
    <hr class="separate-line" />
    <div>
      <el-form
        ref="form"
        :model="report"
        @submit.prevent.native="submit"
        :validate-on-rule-change="false"
        label-position="right"
        label-width="260px"
      >
        <el-form-item label="Teacher:">
          <div v-if="isRoleAdmin()">
            <el-select
              :value="reportTeacher.id" 
              @change="setCurrentReportTeacherId"
            >
              <el-option
                :key="teacher.teacher_user_id"
                v-for="teacher in sessionClass.session_class_teachers"
                :label="teacher.teacher_user.name"
                :value="teacher.teacher_user_id"
              />
            </el-select>
            <router-link
              :to="{
                name: 'ProfileForAdmin',
                params: { id: reportTeacher.id }
              }"
            >
              <i class="fa fa-eye action-icon" />
            </router-link>
          </div>
          <div v-else>
            <router-link
              :to="{
                name: 'ProfileForAdmin',
                params: { id: reportTeacher.id }
              }"
            >
              {{ reportTeacher.name }}
            </router-link>
          </div>
        </el-form-item>
        <el-form-item label="Students:">
          <UserNames
            :users="
              students.map(({ student }) => ({
                id: student.id,
                name: `${student.first_name} ${student.last_name}`
              }))
            "
          />
        </el-form-item>
        <el-form-item label="Course:">
          <span v-if="isEditMode">
            {{ sessionClass.title }}
          </span>
          <el-select
            v-else
            v-model="classId"
            placeholder="Course"
            style="width: 100%"
          >
            <el-option
              v-for="couse in couses"
              :key="couse.id"
              :label="`${couse.title} (${couse.student_user_names})`"
              :value="couse.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item required label="Progress for:">
          <el-row v-if="!isCounseling">
            <el-col :span="24">
              <el-select v-model="report.timeRangeType" style="width: 100%">
                <el-option :key="1" :value="1" label="Monthly" />
                <el-option :key="2" :value="2" label="Weekly" />
                <el-option :key="3" :value="3" label="Custom" />
              </el-select>
            </el-col>
          </el-row>
          <el-row v-if="report.timeRangeType === 1">
            <el-col :span="12">
              <el-select v-model="report.year" style="width: 100%">
                <el-option
                  :key="y"
                  :value="new Date().getFullYear() + 1 - y"
                  v-for="y in 12"
                >
                  {{ new Date().getFullYear() + 1 - y }}
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="12">
              <el-select v-model="report.month" style="width: 100%">
                <el-option
                  :key="m"
                  :value="`${m.toString().length === 1 ? '0' : ''}${m}`"
                  v-for="m in 12"
                >
                  {{ `${m.toString().length === 1 ? "0" : ""}${m}` }}
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row v-else-if="report.timeRangeType === 2 || 3" :gutter="20">
            <el-col :span="12">
              <el-date-picker
                type="date"
                v-model="report.from"
                placeholder="日期"
                style="width: 100%"
              />
            </el-col>
            <el-col :span="12">
              <el-date-picker
                type="date"
                v-model="report.to"
                placeholder="日期"
                style="width: 100%"
              />
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="Is this the last report for this course?">
          <el-radio-group v-model="report.isLast">
            <el-radio :label="true">Yes</el-radio>
            <el-radio :label="false">No</el-radio>
          </el-radio-group>
        </el-form-item>
        <hr class="separate-line" />
        <div style="margin: 20px 0px">
          <template v-if="lessonLogs.length === 0">
            <el-alert title="No Lessons logs." type="warning" />
          </template>
          <template v-else>
            <el-tabs v-model="currenctLessonTab" @tab-click="() => {}">
              <template
                v-if="(isRoleAdmin() || isRoleTeacher())"
              >
                <el-tab-pane
                  :name="String(teacher.teacher_user_id)"
                  :key="teacher.teacher_user_id"
                  v-for="teacher in teachers"

                >
                  <template slot="label">
                    <i class="fas fa-user-graduate" />
                    {{ teacher.teacher_user.name }}
                  </template>
                  <TeachingLogs
                    :isPrivateLesson="isPrivateLesson"
                    :classId="Number($route.params.classId)"
                    :teachingLogs="teachingLogs[teacher.teacher_user_id]"
                    :teacherUserId="teacher.teacher_user_id"
                    :teacherUserName="teacher.teacher_user.name"
                    @reloadCustomLesson="queryLessonLogs"
                  />
                </el-tab-pane>
              </template>
              <template>
                <el-tab-pane
                  :key="student.id"
                  v-for="{ student } in students"
                  :label="student.name"
                  :name="String(student.id)"
                >
                  <template slot="label">
                    <i class="far fa-user" />
                    {{ student.name }}
                  </template>
                  <LessonLogs
                    :isPrivateLesson="isPrivateLesson"
                    :classId="Number($route.params.classId)"
                    :lessonLogs="lessonLogs[student.id]"
                    :enrollSessionId="getEnrollSessionId(student.id)"
                    @reloadCustomLesson="queryLessonLogs"
                  />
                </el-tab-pane>
              </template>
            </el-tabs>
          </template>
        </div>
        <el-form-item :label="`${reportColumnNames[report.timeRangeType]}:`">
          <el-input v-model="report.report" type="textarea" />
        </el-form-item>
        <el-form-item
          :label="`${planForNextColumnNames[report.timeRangeType]}:`"
        >
          <el-input v-model="report.plan" type="textarea" />
        </el-form-item>
        <el-form-item :label="hasMultipleStudents ? 'Individual notes:' : 'Notes:'">
          <el-row>
            <el-col v-if="hasMultipleStudents" :span="6">
              <el-menu :defaultActive="String(currentStudentId)">
                <el-menu-item
                  :index="String(student.studentClassTicketId)"
                  :key="student.studentClassTicketId"
                  v-for="student in students"
                  @click="
                    () => {
                      changeCurrentStudent(student.studentClassTicketId);
                    }
                  "
                >
                  {{ student.student.name }}
                </el-menu-item>
              </el-menu>
            </el-col>
            <el-col :span="hasMultipleStudents ? 18 : 24">
              <el-input
                :value="individualNote"
                @input="
                  note => {
                    setStudentNote(note);
                  }
                "
                type="textarea"
              />
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">Save 儲存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import moneyMixin from "@/mixins/money";
import privateApi from "@/apis/private";
import reportApi from "@/apis/report";
import lessonLogApi from "@/apis/lessonLog";
import courseApi from "@/apis/course";
import formMixin from "@/mixins/form";
import roleMixin from "@/mixins/role";
import Breadcrumb from "@/components/Breadcrumb";
import UserNames from "@/components/names/UserNames";
import TeachingLogs from "@/views/lessonLog/TeachingLogs";
import LessonLogs from "@/views/lessonLog/LessonLogs";
import IsOnlineIcon from "@/components/icon/IsOnline";
import { reportColumnNames, planForNextColumnNames } from "./dictionarys";

export default {
  metaInfo() {
    return {
      title: `${
        this.isEditMode
          ? this.$t("pageTitle.editProgressReport")
          : this.$t("pageTitle.addProgressReport")
      } - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    UserNames,
    TeachingLogs,
    LessonLogs,
    IsOnlineIcon
  },
  mixins: [formMixin, moneyMixin, roleMixin],
  data() {
    return {
      report: {
        timeRangeType: 1,
        from: null,
        to: null,
        month: "",
        year: "",
        report: "",
        plan: "",
        isLast: false
      },
      currenctLessonTab: "teacher",
      teachingLogs: [],
      lessonLogs: [],
      students: [],
      currentStudentId: "",
      classId: Number(this.$route.params.classId),
      couses: [],
      reportTeacher: { id: "" },
      sessionClass: {
        course_session: {}
      },
      teachers: []
    };
  },
  watch: {
    "report.timeRangeType"(timeRangeType) {
      this.setDefaultReportDate(timeRangeType);
      this.queryLessonLogs();
    },
    "report.from"() {
      this.queryLessonLogs();
    },
    "report.to"() {
      this.queryLessonLogs();
    },
    "report.month"() {
      this.transferToDateRange();
    },
    "report.year"() {
      this.transferToDateRange();
    }
  },
  computed: {
    hasMultipleStudents() {
      return this.students.length > 1;
    },
    isPrivateLesson() {
      return this.$route.name.indexOf("privateClasses") !== -1;
    },
    reportColumnNames() {
      return reportColumnNames;
    },
    planForNextColumnNames() {
      return planForNextColumnNames;
    },
    isEditMode() {
      return this.$route.name.indexOf("EditReport") !== -1;
    },
    individualNote() {
      const student = this.students.find(
        student => student.studentClassTicketId === this.currentStudentId
      );
      if (student) {
        return student.note;
      }
      return "";
    },
    isCounseling() {
      return this.$route.params.privateType === "counseling";
    },
    timeRangeIsMonth() {
      return 1;
    },
    timeRangeIsCustom() {
      return 3;
    }
  },
  async created() {
    await this.initialExchangeRate();
    await this.setClassInfo();
    if (this.isEditMode) {
      this.setEditReport();
    } else {
      this.setDefaultReportDate(this.timeRangeIsMonth);
    }
    this.fetchCoursesByTeacher();
  },
  methods: {
    setCurrentReportTeacherId(teacherUserId) {
      const reportTeacher = this.sessionClass.session_class_teachers.find(
        ({ teacher_user_id }) => teacher_user_id === teacherUserId
      );
      this.reportTeacher = {
        id: reportTeacher.teacher_user.id,
        name: reportTeacher.teacher_user.name
      };
      this.fetchCoursesByTeacher();
      this.queryLessonLogs();
    },
    async setEditReport() {
      const { class_progress_report: report } = await reportApi.fetchReport(
        this.$route.params.reportId
      );
      this.report = {
        timeRangeType: report.time_range_type,
        from: report.from,
        to: report.to,
        month: moment(report.from).format("MM"),
        year: moment(report.from).format("YYYY"),
        report: report.report,
        plan: report.plan,
        isLast: false
      };

      this.reportTeacher = {...report.teacher_user};

      this.students = report.student_progress_reports.map(studentReport => ({
        studentReportId: studentReport.id,
        studentClassTicketId: studentReport.student_class_ticket_id,
        student: studentReport.student_user,
        note: studentReport.note_to_student
      }));
    },
    promptNextStepForDelete() {
      this.$message({
        showClose: true,
        message:
          "如想要在此移除這筆 Lesson Log，請將 Progress for 設置為 Custom ; 如果是想刪除此筆 Lesson Log，請至此課程明細刪除。",
        type: "warning"
      });
    },
    transferToDateRange() {
      const defaultDate = `${this.report.year}-${this.report.month}-01`;
      this.report.from = moment(defaultDate).startOf("month");
      this.report.to = moment(defaultDate).endOf("month");
      this.queryLessonLogs();
    },
    changeCurrentStudent(studentClassTicketId) {
      this.currentStudentId = studentClassTicketId;
    },
    async setClassInfo() {
      const {
        student_class_tickets: studentClassTickets,
        session_class: sessionClass
      } = await privateApi.getCustomLesson({
        class_id: Number(this.$route.params.classId)
      });

      this.sessionClass = sessionClass;
      if (this.isRoleAdmin()) {
        const [{ teacher_user: teahcer }] = this.sessionClass.session_class_teachers;
        this.reportTeacher = {
          id: teahcer.id,
          name: teahcer.name
        };
      } else {
        this.reportTeacher = {
          id: this.$store.getters["user/getProfile"].id,
          name: this.$store.getters["user/getProfile"].name
        };
      }

      this.students = studentClassTickets.map(studentClassTicket => ({
        ...studentClassTicket,
        studentClassTicketId: studentClassTicket.id,
        note: ""
      }));

      this.changeCurrentStudent(studentClassTickets[0].id);

      this.teachers = this.sessionClass.session_class_teachers;
      this.currenctLessonTab = String(this.teachers[0].teacher_user_id);
    },
    async queryLessonLogs() {
      const query = {
        class_id: this.sessionClass.id,
        // teacher_user_id: this.reportTeacher.id,
        from: moment(this.report.from).format("YYYY-MM-DD"),
        to: moment(this.report.to).format("YYYY-MM-DD")
      };
      if(!this.isPrivateLesson) {
        const query = {
          class_id: this.sessionClass.id,
          from: moment(this.report.from).format("YYYY-MM-DD"),
          to: moment(this.report.to).format("YYYY-MM-DD")
        };
      };
      
      const {
        teaching_logs_group_by_user,
        lesson_logs_group_by_user
      } = await lessonLogApi.fetchLessonLogByQuery(query);
      this.teachingLogs = teaching_logs_group_by_user;
      this.lessonLogs = lesson_logs_group_by_user;
    },
    setStudentNote(note, studentId) {
      const targetStudentId = studentId || this.currentStudentId;
      const targetStudentIndex = this.students.findIndex(
        student => student.studentClassTicketId === targetStudentId
      );
      this.students = this.students.map((student, index) => {
        if (index !== targetStudentIndex) return student;
        return { ...student, note };
      });
    },
    async fetchCoursesByTeacher() {
      const { session_classes } = await courseApi.fetchCoursesByTeacher(
        this.reportTeacher.id
      );
      this.couses = session_classes.map(sessionClass => ({ ...sessionClass }));
    },
    setDefaultReportDate(timeRangeType) {
      if (timeRangeType === this.timeRangeIsMonth) {
        const todayDate = moment().format("DD");
        let defaultDate = moment();
        const middleOfMonth = 15;
        if (todayDate < middleOfMonth) {
          defaultDate = moment().add(-1, "M");
        }

        this.report.from = moment(defaultDate).startOf("month");
        this.report.to = moment(defaultDate).endOf("month");
        this.report.year = Number(defaultDate.format("YYYY"));
        this.report.month = Number(defaultDate.format("MM"));
      } else {
        const todayDay = new Date().getDay();
        let theSpecDay = { from: moment(), to: moment() };
        if (todayDay < 2) {
          Object.keys(theSpecDay).forEach(key => {
            theSpecDay[key] = theSpecDay[key].add(-1, "w");
          });
        }
        this.report.from = theSpecDay.from.startOf("week").add(1, "d");
        this.report.to = theSpecDay.to.endOf("week").add(1, "d");
      }
    },
    getEnrollSessionId(studentId) {
      try {
        return this.students.find(student => student.id === studentId)
          .enrolled_session_id;
      } catch (e) {
        return null;
      }
    },
    async updateReport(report) {
      await reportApi.updateReport(report);
    },
    async createReport(report) {
      if (this.isPrivateLesson) {
        await reportApi.createReport(report);
      } else {
        await reportApi.createGroupClassReport(report);
      }
    },
    backToTheDetail() {
      if (this.isPrivateLesson) {
        this.$router.push({
          path: `/private/${this.$route.params.privateType}/${this.$route.params.classId}/detail`
        });
      } else {
        this.$router.push({
          name: "classDetail",
          params: {
            classId: this.$route.params.classId
          }
        });
      }
    },
    async onSubmit() {
      const valid = this.report.from && this.report.to;
      if (!valid) {
        this.$message.error(this.$t("message.form_validation_error"));
        return;
      }

      const report = {
        class_id: Number(this.$route.params.classId),
        teacher_user_id: this.reportTeacher.id,
        time_range_type: this.report.timeRangeType,
        from: moment(this.report.from).format("YYYY-MM-DD"),
        to: moment(this.report.to).format("YYYY-MM-DD"),
        report: this.report.report,
        plan: this.report.plan
      };

      try {
        if (this.isEditMode) {
          report.class_progress_report_id = Number(this.$route.params.reportId);
          report.students = this.students.map(student => ({
            student_progress_report_id: student.studentReportId,
            note_to_student: student.note
          }));
          await this.updateReport(report);
        } else {
          report.students = this.students.map(student => ({
            student_class_ticket_id: student.studentClassTicketId,
            note_to_student: student.note
          }));
          await this.createReport(report);
        }
        this.$message({
          showClose: true,
          message: this.$t("message.create_success"),
          type: "success"
        });

        this.backToTheDetail();
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style scoped></style>
