<template>
  <div>
    <span :key="user.id" v-for="(user, index) in users">
      <a v-if="user.id" :href="`/user/${user.id}`">{{ `${user.name}` }}</a
      ><span v-else>{{ `${user.name}` }}</span
      ><span>
        <span v-if="users.length - 2 === index">
          <span v-if="users.length > 2">,</span> and
        </span>
        <span v-else-if="users.length - 1 !== index">, </span>
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: "UserNames",
  props: {
    users: {
      type: Array,
      default: () => []
    }
  }
};
</script>
